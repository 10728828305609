<template>
  <div class="health">
    <div class="oItem-t">
      <div class="title">垃圾分类</div>
      <div class="date">{{ date }}</div>
    </div>
    <div class="oItem-b">
      <p>拍照上传</p>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.proposalPic1"
        :activityPicture.sync="form.imageUrls"
        :maxCount="3"
        ref="load"
      ></v-upload>
      <p class="upload_text">
        请上传您的厨余垃圾分类照片，且建议单个图片，不超过5M。
      </p>
      <p class="upload_text">
        每日最多上传两次照片，若审核不合格，需学习视频，可获得再次上传照片的机会。
      </p>
    </div>
    <div class="submit" @click="submit">确认提交</div>
  </div>
</template>

<script>
import { postRubUrl, rubTopicInfoUrl } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "health",
  data() {
    return {
      date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      timer: null,
      id: "",
      indexData: {},
      form: {
        proposalPic1: "",
        imageUrls: "",
        proposalContent: "",
      },
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  created() {
    this.id = this.$route.query.id;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
    this.getIndexData();
    // gloabalCount("", 4, 1);
  },
  methods: {
    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.indexData = res.data;
      } else {
      }
    },
    async submit() {
      if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }
        if (!this.form.imageUrls) {
          this.$toast("请上传图片");
          return;
        }
        this.postData();
      }
    },
    change() {},
    postData() {
      let params = {
        houseId: this.roomId,
        userId: this.userId,
        imageUrls: this.form.imageUrls,
        topicId: this.indexData.id,
      };
      this.$axios.get(`${postRubUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功");
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "rubbishSortList",
            });
          }, 200);
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.health {
  min-height: 100vh;
  background: #f3f4f9;
  .oItem-t,
  .oItem-c,
  .oItem-b {
    background: #fff;
  }
  .oItem-t {
    padding: 32px 32px 16px 32px;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #1a1c34;
      line-height: 50px;
    }
  }
  .oItem-c {
    padding: 32px;
    ::v-deep .van-field {
      background: #f7f8fa;
    }
  }
  .oItem-b {
    padding: 32px;
    p {
      font-size: 32px;
      font-weight: bold;
      color: #666666;
      line-height: 44px;
    }
    .oItem-b_upload {
      margin: 32px 0 38px;
      ::v-deep .van-uploader__preview {
        border: 2px dashed #eee;
      }
      ::v-deep .van-uploader__preview-image {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        margin: 0;
      }
    }
    .upload_text {
      padding-left: 20px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 34px;
      margin-top: 10px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background: #b0b1b4;
        border-radius: 6px;
        top: 12px;
        left: 0px;
      }
    }
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    width: 100%;
    height: 96px;
    background: #178af7;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
}
</style>
<style lang="less">
.wishes {
  .van-radio__label {
    font-size: 30px;
  }
}
</style>
